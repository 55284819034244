<template>
<div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="`${products.length} produtos`" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" v-model="search" placeholder="Procurar">
                                <button type="submit" class="btn btn-info" @click="fetchProducts">Buscar</button>
                            </div>
                           
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'ProductNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo produto</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list" v-for="item in products" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                        </div>
                                        <div class="col-8 col-md-7 col-lg-10 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.quantity }} - {{ item.name }} </h6>
                                                    </div>
                                                    <div class="col-12 text-muted pad-content-list">
                                                        <h6 class="text-truncate empreendi">Referência: {{ item.reference }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'ProductEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                            <a class="btn btn-danger text-white rounded-circle btn-circle font-16 ml-2" href="javascript:void(0);" @click="removeProduct(item.id)"><i class="far fa-trash-alt"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 text-center p-4" v-if="products.length == 0">
                    Nenhum produto encontrado.
                </div>
            </div>
            <div class="col-12">
                <BasePagination
                    v-if="products.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
            </div>
        </div>
</div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
const service = ProductService.build();

export default {
    data(){
        return{
            products: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'clientes',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ]
        }
    },
    methods:{
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchProducts();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchProducts();
        },
        removeProduct(id){

          this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse produto?')
          .then(value => {

            if(value) {
                let data = {
                    id: id
                };

                service
                .destroy(data)
                .then(resp => {
                    this.$bvToast.toast('Seu produto foi excluído com sucesso!', {
                        title: 'Excluir produto',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchProducts();
                })
                .catch(err => {
                    this.$bvToast.toast('Seu produto não pode ser excluído.', {
                        title: 'Excluir produto',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })
            }
               
          })
          .catch(err => {
            // An error occurred
          })
            

        },
        fetchProducts(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    this.products = resp.data;
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    mounted(){
        this.fetchProducts();
    }
}
</script>